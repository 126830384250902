((navbar: HTMLElement) => {
  let path = window.location.pathname;
  if (path === '/index.html' || path === '/index') path = '/';
  path = path.replace('.html', '');

  const anchor = navbar.querySelector(`a[href="${path}"]:not(.db-logo)`);
  anchor?.parentElement?.classList.add('active');

  const button = document.getElementById('menu-button');

  const wrapper = document.getElementById('nav-wrapper');

  button?.addEventListener('click', () => {
    button.classList.toggle('open');
    wrapper?.classList.toggle('is-open');
  });
})(document.querySelector('.db-navbar') as any);
